@use 'component/utility' as *;

.g-header {
    // display: none;
    @include mq(sp){
        pointer-events: none;
    }
    .g-header__logo,
    .c-line-link, 
    .c-menu-btn {
        opacity: 0;
        pointer-events: none;
        &.show,&.show-g {
            opacity: 1;
            pointer-events: initial;
        }
    } 
}

.home-main {

}

.home-header {
    position: relative;
    width: 100%;
    padding: 0 0 50px;
    background: #FFF6ED;
    @include mq(sp){
        padding: vw(75) 0 vw(50);
    }

    .home-header__bar {
    }

    .home-logo {
        position: absolute;
        right: vwPC(-73);
        top: vwPC(-65);
        width: vwPC(326);
        height: vwPC(295);
        z-index: 20;
        @include mq(sp){
            right: initial;
            left: vw(-16);
            top: vw(-36);
            width: vw(190);
            height: vw(171);
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    .c-menu-btn {
        position: absolute;
        right: vwPC(65);
        top: vwPC(320);
        @include mq(sp){
            right: vw(20);
            top: vw(20);
        }
    }

    .c-line-link {
        position: absolute;
        right: vwPC(65);
        top: vwPC(387);
        @include mq(sp){
            right: vw(69);
            top: vw(20);
        }
    }

    .home-slider {
        width: vwPC(1200);
        overflow: hidden;
        border-radius: 0 0 vwPC(60) 0;
        @include mq(sp){
            width: 100%;
            border-radius: 0 0 vw(30) 0;
        }
        img {
            width: 100%;
            height: vwPC(630);
            object-fit: cover;
            @include mq(sp){
                height: vw(375);
            }
        }
    }
    .swiper-nav {
        position: absolute;
        right: vwPC(34.4);
        bottom: 50px;
        width: 100px;
        height: 15.42px;
        @include mq(sp){
            display: flex;
            justify-content: center;
            width: 100%;
            right: initial;
            bottom: vw(20);
        }
        .swiper-button-prev, .swiper-button-next {
            display: block;
            top: 0;
            width: 9.12px;
            height: 15.42px;
            margin-top: 0;
            outline: none;
            z-index: 2;
            @include mq(sp){
                position: relative;
            }
            &:after {
                display: none;
            }
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .swiper-pagination {
            @include mq(sp){
                position: relative;
                width: auto;
                margin: 0 13px;
            }
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-next {
            right: 0;
        }
        .swiper-pagination-bullets.swiper-pagination-horizontal {
            z-index: 1;
        }
        .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
            bottom: 2px;
        }
        .swiper-pagination-bullet {
            width: 9px;
            height: 9px;
            background: #BF8B55;
            opacity: 1;
            margin: 0 5px;
            outline: none;
        }
        .swiper-pagination-bullet-active {
            background: $brown;
        }
    }
}

.home-news {
    padding: 60px 20px 97px;
    background: #fff;
    @include mq(sp){
        padding: 30px 30px 50px;
    }
    &__in {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1100px;
        margin: 0 auto;
        @include mq(sp){
            flex-direction: column;
        }

        .home-newslist {
            // max-width: 884px;
            width: calc(100% - 216px);
            @include mq(sp){
                width: 100%;
            }
            li {
                &:not(:nth-child(1)) {
                    margin-top: 15px;
                    @include mq(sp){
                        margin-top: 10px;
                    }
                }
                a {
                    display: flex;
                    padding: 10px 30px;
                    background: #FAF8F0;
                    border-radius: 22px;
                    line-height: 1.2857;
                    @include mq(pc){
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                    @include mq(sp){
                        display: block;
                        padding: 20px 20px 17px;
                        border-radius: 20px;
                    }
                    .entry-date {
                        margin-top: 3px;
                        margin-right: 36px;
                        color: #bf8b55;
                        font-family: $ff-js;
                        font-weight: $fw-r;
                        white-space: nowrap;
                        @include mq(sp){
                            margin-top: 0;
                            margin-right: 0;
                            font-size: 12px;
                        }
                    }
                    .entry-title {
                        line-height: 1.4286;
                        @include text-lc(2);
                        @include mq(sp){
                            margin-top: 6px;
                            font-size: 12px;
                        }
                    }

                }
            }
        }
        .home-newsbtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 52px;
            @include mq(pc){
                &:hover {
                    opacity: 0.6;
                }
            }
            @include mq(sp){
                width: 142px;
                margin: 23px auto 0;
            }
            &__txt {
                text-align: center;
                &__ja {
                    font-size: 16px;
                    line-height: 1.5;
                    white-space: nowrap;
                    @include mq(sp){
                        font-size: 14px;
                    }
                }
                &__en {
                    margin-top: 6px;
                    color: #c3af9a;
                    font-size: 12px;
                    font-family: $ff-js;
                    font-weight: $fw-r;
                    line-height: 1.16666667;
                    @include mq(sp){
                        font-size: 12px;
                    }
                }
            }
            .ico-arr-right {
                width: 31px;
                height: 31px;
                margin-left: 13px;
                @include mq(sp){
                    width: 23px;
                    height: 23px;
                    margin-left: 15px;
                }
            }
        }
    }
}

.home-shop {
    padding: 0 20px;
    @include mq(sp){
        padding: 0;
    }
    &__in {
        max-width: 1100px;
        margin: 0 auto;
    }
    &__header {
        margin-top: -20px;
        text-align: center;
        @include mq(sp){
            margin-top: -10px;
        }
        &__txten {
            font-size: 50px;
            font-family: $ff-js;
            font-weight: $fw-b;
            letter-spacing: 0;
            line-height: 1.22;
            @include mq(sp){
                font-size: 30px;
            }
        }
        &__txtja {
            margin-top: 10px;
            line-height: 1.42857143;
            @include mq(sp){
                margin-top: 5px;
            }
        }
    }
}
.home-shopnav {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;
    padding: 0 30px;
    @include mq(sp){
        margin-top: 25px;
    }
    li {
        @include mq(pc){
            width: 23.6%;
            &:not(:nth-child(4n)) {
                margin-right: 1.85%;
            }
            &:not(:nth-child(-n+4)) {
                margin-top: 20px;
            }
        }
        @include mq(sp){
            width: 49.2%;
            &:not(:nth-child(2n)) {
                margin-right: 1.6%;
            }
            &:not(:nth-child(-n+2)) {
                margin-top: 10px;
            }
        }
        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 17px 20px 18px 30px;
            background: #fff;
            border-radius: 40px;
            @include mq(pc){
                &:hover {
                    opacity: 0.6;
                }
            }
            @include mq(sp){
                padding: 11px 5px 12px 15px;
            }
            span {
                font-size: 16px;
                @include mq(sp){
                    font-size: 12px;
                }
            }
            .ico-arr-down {
                width: 23px;
                height: 23px;
                // margin-left: 10px;
                @include mq(sp){
                    width: 20px;
                    height: 20px;
                    margin-left: 0;
                }
            }
        }
    }
}
.home-shoplist-title {
    margin-top: 60px;
    font-size: 24px;
    font-weight: $fw-b;
    text-align: center;
    @include mq(sp){
        margin-top: 20px;
        font-size: 16px;
    }
}
.home-shoplist {
    margin-top: 30px;
    @include mq(sp){
        margin-top: 60px;
        padding: 0 10px;
    }
    &__item {
        display: flex;
        margin-left: 60px;
        padding: 50px 58px 50px 0;
        background: #fff;
        border-radius: 60px;
        @include mq(sp){
            display: block;
            margin-left: 0;
            padding: 0 20px 25px;
            border-radius: 30px;
        }
        &:not(:nth-child(1)) {
            margin-top: 30px;
            @include mq(sp){
                margin-top: 60px;
            }
        }
        &__pic {
            width: 41.8%;
            margin-left: -6.1%;
            @include mq(sp){
                width: 100%;
                margin-left: 0;
            }
            img {
                border-radius: 30px;
                @include mq(sp){
                    margin-top: -40px;
                    border-radius: 15px;
                }
            }
        }
        &__body {
            width: 60.3%;
            margin-left: 4%;
            @include mq(sp){
                width: 100%;
                margin-left: 0;
                margin-top: 15px;
            }
            .shop-name {
                span {
                    display: inline-block;
                    padding: 3px 16px 4px 12px;
                    background: $brown;
                    border-radius: 0px 13.5px 13.5px 0px;
                    color: #fff;
                    line-height: 1.42857143;
                    @include mq(sp){
                        font-size: 14px;
                        border-radius: 0px 14px 14px 0px;
                    }
                }
            }
            .shop-name2 {
                margin-top: 10px;
                font-size: 24px;
                line-height: 1.4583;
                @include mq(sp){
                    font-size: 20px;
                }
            }
            .shop-tb-list {
                margin-top: 20px;
                padding-bottom: 25px;
                border-bottom: 1px solid #BF8B55;
                @include mq(sp){
                    margin-top: 15px;
                    padding-bottom: 15px;
                }
            }
            .shop-tb {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                &:not(:nth-child(1)) {
                    margin-top: 20px;
                    @include mq(sp){
                        margin-top: 12px;
                    }
                }
                &__title {
                    width: 87px;
                    @include mq(sp){
                        width: 80px;
                    }
                    span {
                        display: inline-block;
                        width: 100%;
                        padding: 3px 0 4px;
                        background: #BF8B55;
                        border-radius: 14px;
                        color: #fff;
                        line-height: 1.42857143;
                        text-align: center;
                        @include mq(sp){
                            font-size: 12px;
                            padding: 3px 0 4px;
                        }
                    }
                }
                &__text {
                    width: calc(100% - 97px);
                    margin-top: 3px;
                    line-height: 1.42857143;
                    @include mq(sp){
                        width: calc(100% - 95px);
                        font-size: 12px;
                    }
                    a {
                        @include mq(pc){
                            &[href^=tel] {
                                pointer-events: none;
                            }
                            &:hover {
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
            .shop-item-name {
                margin-top: 25px;
                font-weight: $fw-b;
                line-height: 1.4286;
                @include mq(sp){
                    margin-top: 15px;
                }
            }
            .shop-item-txt {
                margin-top: 15px;
                line-height: 1.4286;
                @include mq(sp){
                    font-size: 12px;
                }
            }
            .shop-btns {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include mq(sp){
                    display: block;
                }
                .shop-btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 49.2%;
                    // padding: 14px 6.9% 14px 13.8%;
                    padding: 14px 0;
                    border-radius: 45px;
                    @include mq(sp){
                        width: 290px;
                        padding: 11px 0;
                        margin: 0 auto;
                        &:not(:nth-child(1)) {
                            margin-top: 10px;
                        }
                    }
                    &--detail {
                        background: #FAF8F0;
                    }
                    &--pdf {
                        background: #F5F5F5;
                    }
                    @include mq(pc){
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                    &__txt {
                        width: calc(100% - 13.8% - 28px);
                        margin-left: 13.8%;
                        @include mq(sp){
                        }
                        &__ja {
                            font-size: 16px;
                            line-height: 1.5;
                            @include mq(sp){
                                font-size: 14px;
                            }
                        }
                        &__en {
                            margin-top: 6px;
                            color: #C0AB91;
                            font-size: 12px;
                            font-family: $ff-js;
                            font-weight: $fw-r;
                            line-height: 1.16666667;
                        }
                    }
                    .ico-arr-right, .ico-pdf {
                        width: 23px;
                        height: 23px;
                        margin-right: 6.9%;
                    }
                }
            }
        }
    }
}
.home-inquiry {
    position: relative;
    max-width: 553px;
    margin: 93px auto 0;
    padding: 54px 20px 25px;
    background: #fff;
    border-radius: 87px;
    text-align: center;
    @include mq(sp){
        width: 315px;
        margin: 53px auto 0;
        padding: 39px 20px 22px;
    }
    &__title {
        position: absolute;
        left: calc(50% - 136px);
        top: -33px;
        width: 272px;
        padding: 21px 0;
        background: $brown;
        border-radius: 87px;
        color: #fff;
        font-size: 17px;
        line-height: 1.4706;
        @include mq(sp){
            left: calc(50% - 115px);
            top: -24px;
            width: 230px;
            padding: 12px 0;
            font-size: 16px;
        }
    }
    &__body {
        font-size: 16px;
        line-height: 1.5;
        @include mq(sp){
            font-size: 14px;
        }
        a {
            @include mq(pc){
                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

}